<template>
  <div class="d-flex flex-column overflow-auto">
    <sticky-header :title="$route.meta.title" />
    <div class="flex-fill p-3">
      <b-row class="mb-4">
        <b-col cols="12">
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-input debounce="500" @update="loadTimesheet" placeholder="Search" type="text" v-model="query" />
        </b-col>
        <b-col cols="12" lg="6">
          <b-input-group>
            <b-form-datepicker v-model="start" />
            <b-input-group-text class="border-left-0 border-right-0"> - </b-input-group-text>
            <b-form-datepicker v-model="end" />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="dayview-width py-3 gap-2">
          <b-list-group class="mb-4" v-if="!loading">
            <b-table
              outlined
              :items="data"
              :fields="['cleaner', ...getDateRange(), 'total', 'overtime']"
            >
              <template #cell(cleaner)="row">
                {{ row.item.cleaner.name }}
              </template>

              <template v-for="(date, key) in getDateRange()" v-slot:[`cell(${date})`]="row">
                <router-link v-bind:key="key" :to="{name: 'attendances', query: {query: row.item.cleaner.name, start: moment(date, 'ddd, DD/MM/YY').format('YYYY-MM-DD'), end: moment(date, 'ddd, DD/MM/YY').format('YYYY-MM-DD')}}">
                  <template v-if="((moment(row.field.key, 'ddd, DD/MM/YY').toDate().getDay()) == 6) || ((moment(row.field.key, 'ddd, DD/MM/YY').toDate().getDay()) == 0)">
                    {{ (convertMinutesToHuman(row.item.days[moment(row.field.key, 'ddd, DD/MM/YY').format('YYYY-MM-DD')]) != '0') ? convertMinutesToHuman(row.item.days[moment(row.field.key, 'ddd, DD/MM/YY').format('YYYY-MM-DD')]) : '-' }}
                  </template>
                  <template v-else>
                    {{ (convertMinutesToHuman(row.item.days[moment(row.field.key, 'ddd, DD/MM/YY').format('YYYY-MM-DD')]) != '0') ? convertMinutesToHuman(row.item.days[moment(row.field.key, 'ddd, DD/MM/YY').format('YYYY-MM-DD')]) : '0h 0m' }}
                  </template>
                </router-link>
              </template>

              <template #cell(total)="row">
                {{ convertMinutesToHuman(calcTotalTime(row.item)) }}
              </template>

              <template #cell(overtime)="row">
                {{ convertMinutesToHuman(calcOvertime(row.item)) }}
              </template>
            </b-table>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style>
@media (min-width: 768px) {
  .dayview-width {
    overflow: auto;
    width: calc(100vw - 319px);
    max-height: calc(100vh - 180px);
    min-height: calc(50vh)
  }
}
@media (max-width: 768px) {
  .dayview-width {
    overflow: auto;
  }
}
</style>

<script>
import moment from 'moment'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { StickyHeader },
  data() {
    return {
      start: moment().add(-6, 'days').toDate(),
      end: moment().toDate(),
      query: '',
    };
  },
  async beforeMount() {
    moment.locale('en-GB')

    await this.loadTimesheet()
  },
  computed: {
    ...mapState('timesheets', ['data', 'loading']),
  },
  methods: {
    ...mapActions('timesheets', ['fetch', 'updateOrCreate']),
    moment,
    async loadTimesheet() {
      await this.fetch({
        start_date: moment(this.start).format('YYYY-MM-DD'),
        end_date: moment(this.end).format('YYYY-MM-DD'),
        query: this.query ?? ''
      });
    },
    getDateRange() {
      const diff = moment(this.end).diff(this.start, 'days');

      if (diff <= 0) {
        return [];
      }

      let result = [];

      for(var i = 0; i <= (diff); i++) {
        result.push(moment(this.start).add(i, 'days').format('ddd, DD/MM/YY'));
      }

      return result;
    },
    convertMinutesToHuman(minutes) {
      if (!minutes || minutes === 0) {
        return '0';
      }
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      return `${hours}h ${mins}m`;
    },
    calcTotalTime(user) {
      let weekTotal = 0;
      let weekendTotal = 0;

      if (!user || !user?.days) return 0;

      let keys = Object.keys(user.days);

      Array.from(keys).forEach((key) => {
        let currentDay = new Date(key).getDay();
        if (currentDay === 6 || currentDay === 0) {
          weekendTotal += user.days[key];
        } else {
          weekTotal += user.days[key];
        }
      })

      return weekTotal + weekendTotal;
    },
    calcOvertime(user) {
      let keys = Object.keys(user.days);
      let dailyContractedHours = user.cleaner.contracted_hours/5 * 60;
      let overtimeTotal = 0;

      Array.from(keys).forEach((key) => {
        let currentDay = new Date(key).getDay();

        if (currentDay === 6 || currentDay === 0) {
          // Didn't work that weekend day
          if (user.days[key] == 0) return;

          overtimeTotal += user.days[key];
        } else {
          let calcTime = user.days[key] - dailyContractedHours;

          overtimeTotal += calcTime;
        }
      })

      return overtimeTotal;
    }
  },
  watch: {
    async '$route'() {
      await this.loadTimesheet()
    },
    date(date) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, date } });
    },
    start() {
      this.loadTimesheet()
    },
    end() {
      this.loadTimesheet()
    }
  }
}


// Week Days
// (6*60) + (6*60) +(6*60) + (6*60) + (8*60) + 16 + (7*60) + 11 + (8*60) + 11 + (7*60) + 14 + (8*60) + 52 +(7*60) + 5 + (6*60) + 23 + (6*60)+ 31 + (8*60)
// 5,503

// Weekend
// (5*60) + 41
// 341
</script>
